import {
  cardTitle,
  defaultFont,
  grayColor,
  successColor,
  tooltip,
} from "assets/jss/material-dashboard-pro-react.js";
import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";

const propertyListItemStyle = (theme) => ({
  ...customDropdownStyle(theme),
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0!important",
    textTransform: "none",
  },
  top: {
    zIndex: "4",
  },

  managerClasses: {
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
    },
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important",
  },
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center",
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0],
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  cardImage: {
    width: 392,
    height: 261,
    objectFit: "cover",
    // objectPosition: "0 0",
  },
});

export default propertyListItemStyle;
