// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import arrayMove from "array-move";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import SortableImages from "components/SortableImages/SortableImages";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./investmentProjectImagesSortStyle";

const useStyles = makeStyles(styles);

export default function InvestmentProjectImagesSort(props) {
  React.useEffect(() => {
    if (props?.investmentProject?.images) {
      setImages(props.investmentProject.images);
      setInvestmentProjectId(props.investmentProject.id);
      setPageLoading(false);
      setDefaultImage(props.investmentProject.defaultImage);
    }
  }, [props]);

  const classes = useStyles();

  const [showDeleteImageModal, setShowDeleteImageModal] = React.useState(false);

  const [currentImage, setCurrentImage] = React.useState();

  const [defaultImage, setDefaultImage] = React.useState();

  const [pageLoading, setPageLoading] = React.useState(true);

  const [investmentProjectId, setInvestmentProjectId] = React.useState();

  const [images, setImages] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();

  const onSortEnd = ({ oldIndex, newIndex }) =>
    setImages((prevImages) => {
      if (defaultImage == oldIndex) {
        setDefaultImage(newIndex);
      } else if (defaultImage > oldIndex && defaultImage <= newIndex) {
        setDefaultImage(defaultImage - 1);
      } else if (defaultImage >= newIndex && defaultImage < oldIndex) {
        setDefaultImage(defaultImage + 1);
      }

      const newImages = arrayMove(prevImages, oldIndex, newIndex);
      return newImages;
    });

  const openDeleteImageModal = (index) => () => {
    setShowDeleteImageModal(true);
    setCurrentImage(index);
  };

  const closeDeleteImageModal = () => {
    setShowDeleteImageModal(false);
    setCurrentImage(undefined);
  };

  const handleDeleteInvestmentProjectImage = async () => {
    try {
      await sendRequest(
        api.deleteInvestmentProjectImage(investmentProjectId, currentImage)
      );

      setImages((prevImages) => {
        let newImages = [...prevImages];
        newImages.splice(currentImage, 1);
        return newImages;
      });

      if (currentImage == defaultImage) setDefaultImage(0);
    } catch (err) {
    } finally {
      closeDeleteImageModal();
    }
  };

  const setInvestmentProjectDefaultImage = (index) => () =>
    setDefaultImage(index);

  const handleUpdateImages = async () => {
    try {
      await sendRequest(
        api.updateInvestmentProjectImages({
          investmentProjectId,
          images,
          defaultImage,
        })
      );
    } catch (err) {}
  };

  return (
    <div>
      <Card>
        <CardBody>
          <SortableImages
            items={images}
            onSortEnd={onSortEnd}
            defaultImage={defaultImage}
            onSetDefaultImage={setInvestmentProjectDefaultImage}
            onDeleteImage={openDeleteImageModal}
          />
          <div className={classes.buttonContainer}>
            <Button
              color="black"
              round={true}
              loading={isLoading && !pageLoading}
              loadingColor="white"
              className={classes.button}
              onClick={handleUpdateImages}
            >
              Update
            </Button>
          </div>
        </CardBody>
      </Card>
      <CustomModal
        style={{ width: 500 }}
        open={showDeleteImageModal}
        onClose={closeDeleteImageModal}
      >
        <h5>Are you sure you want to delete this image?</h5>
        <img className={classes.image} src={images[currentImage]} />
        <div className={classes.modalButtonContainer}>
          <Button
            color="black"
            round={true}
            loadingColor="white"
            onClick={closeDeleteImageModal}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            round={true}
            loading={isLoading && !pageLoading}
            loadingColor="white"
            onClick={handleDeleteInvestmentProjectImage}
            className={classes.saveButton}
          >
            Delete
          </Button>
        </div>
      </CustomModal>
    </div>
  );
}
