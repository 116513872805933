// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import UploadImagesIcon from "@material-ui/icons/Image";
import InfoIcon from "@material-ui/icons/Info";
import SortImagesIcon from "@material-ui/icons/Sort";
import CustomTabs from "components/CustomTabs/CustomTabs";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import PropertyDetails from "../../components/PropertyDetails/PropertyDetails";
import PropertyImagesSort from "../../components/PropertyImagesSort/PropertyImagesSort";
import PropertyImagesUpload from "../../components/PropertyImagesUpload/PropertyImagesUpload";
import styles from "./propertyAssignStyle";

const useStyles = makeStyles(styles);

export default function PropertyAssign() {
  const classes = useStyles();
  const [id] = React.useState(window.location.pathname.split("/").pop().trim());

  React.useEffect(() => {
    (async () => {
      try {
        const locations = await sendRequest(api.getLocations());
        setLocations(locations);
        if (id !== "create") {
          const property = await sendRequest(api.getProperty(id));
          setProperty(property);
        }
      } catch (err) {}
    })();
  }, []);

  const handleUpdatePropertyImages = (data) =>
    setProperty((prevProperty) => ({ ...prevProperty, ...data }));

  const [property, setProperty] = React.useState();
  const [locations, setLocations] = React.useState([]);

  const [sendRequest] = useHttpClient();

  let tabs = [
    {
      tabName: "Details",
      tabIcon: InfoIcon,
      tabContent: <PropertyDetails property={property} locations={locations} />,
    },
  ];
  
  if (id !== "create") {
    tabs.push(
      {
        tabName: "Upload Images",
        tabIcon: UploadImagesIcon,
        tabContent: (
          <PropertyImagesUpload
            property={property}
            setPropertyImages={handleUpdatePropertyImages}
          />
        ),
      },
      {
        tabName: "Sort Images",
        tabIcon: SortImagesIcon,
        tabContent: <PropertyImagesSort property={property} />,
      }
    );
  }

  return (
    <div>
      <CustomTabs
        headerColor="primary"
        rootClasses={classes.customTabs}
        tabs={tabs}
      />
    </div>
  );
}
