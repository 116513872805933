import {
  blackColor,
  cardTitle,
  defaultFont,
  hexToRgb,
  successColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react";
import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const propertyAssignStyle = (theme) => ({
  ...customDropdownStyle(theme),
  search: {
    margin: "0",
    paddingTop: "7px",
    paddingBottom: "7px",
    // [theme.breakpoints.down("sm")]: {
    //   margin: "10px 15px",
    //   float: "none !important",
    //   paddingTop: "1px",
    //   paddingBottom: "1px",
    //   padding: "10px 15px",
    //   width: "auto",
    // },
  },
  searchInput: {
    paddingTop: "2px",
  },
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0!important",
    textTransform: "none",
  },
  buttonLink: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "5px 15px 0",
      width: "auto",
      height: "auto",
      "& svg": {
        width: "30px",
        height: "24px",
        marginRight: "19px",
        marginLeft: "3px",
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        width: "30px",
        fontSize: "24px",
        lineHeight: "30px",
        marginRight: "19px",
        marginLeft: "3px",
      },
    },
  },
  searchButton: {
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      //   top: "-50px !important",
      marginRight: "38px",
      float: "right",
    },
  },
  top: {
    zIndex: "4",
  },
  searchIcon: {
    width: "17px",
    zIndex: "4",
  },
  links: {
    width: "20px",
    height: "20px",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "30px",
      height: "30px",
      color: "inherit",
      opacity: "0.8",
      marginRight: "16px",
      marginLeft: "-5px",
    },
  },
  managerClasses: {
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
    },
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important",
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },

  topContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 60,
  },
  searchContainer: { display: "flex", alignItems: "center" },
  button: { "& > span:first-child": { color: whiteColor } },

  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
  },
  mrAuto: {
    marginRight: "auto",
  },
  mlAuto: {
    marginLeft: "auto",
  },
  contentLabel: {
    color: "#AAAAAA",
    fontSize: 11,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    margin: 0,
  },

  customTabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
  },
});

export default propertyAssignStyle;
