// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Table from "components/Table/Table.js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./testimonialsStyle";

const useStyles = makeStyles(styles);

export default function Testimonials() {
  const classes = useStyles();

  const {
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
    clearErrors,
  } = useForm({
    defaultValues: { name: "", text: "" },
  });

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getTestimonials());
        setTestimonials(response);
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [testimonials, setTestimonials] = React.useState([]);

  const [currentTestimonial, setCurrentTestimonial] = React.useState();

  const [showTestimonialModal, setShowTestimonialModal] = React.useState(false);

  const [
    showDeleteTestimonialModal,
    setShowDeleteTestimonialModal,
  ] = React.useState(false);

  const [emails, setEmails] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => {
    setValue(prop, event.target.value);
  };

  const handleAddTestimonial = () => {
    const newIndex = testimonials.length;
    openTestimonialModal(newIndex)();
  };

  const openTestimonialModal = (index) => () => {
    setShowTestimonialModal(true);
    setCurrentTestimonial(index);
    setValue("name", testimonials[index]?.name || "");
    setValue("text", testimonials[index]?.text || "");
  };

  const closeTestimonialModal = () => {
    setShowTestimonialModal(false);
    setValue("name", "");
    setValue("text", "");
    setCurrentTestimonial(undefined);
    clearErrors("name");
    clearErrors("text");
  };

  const openDeleteTestimonialModal = (index) => () => {
    setShowDeleteTestimonialModal(true);
    setCurrentTestimonial(index);
  };

  const closeDeleteTestimonialModal = () => {
    setShowDeleteTestimonialModal(false);
    setCurrentTestimonial(undefined);
  };

  const handleSaveTestimonial = async () => {
    try {
      const response = await sendRequest(
        api.updateTestimonial({
          ...testimonials[currentTestimonial],
          name: getValues("name"),
          text: getValues("text"),
        })
      );

      setTestimonials((prevTestimonials) => {
        let newTestimonials = [...prevTestimonials];
        newTestimonials[currentTestimonial] = response;
        return newTestimonials;
      });
    } catch (err) {
    } finally {
      closeTestimonialModal();
    }
  };

  const handleDeleteTestimonial = async () => {
    try {
      await sendRequest(
        api.deleteTestimonial(testimonials[currentTestimonial].id)
      );

      setTestimonials((prevTestimonials) => {
        let newTestimonials = [...prevTestimonials];
        newTestimonials.splice(currentTestimonial, 1);
        return newTestimonials;
      });
    } catch (err) {
    } finally {
      closeDeleteTestimonialModal();
    }
  };

  const tableData = testimonials.map((testimonial, index) => [
    testimonial.name,
    testimonial.text,
    <EditIcon
      className={classes.editIcon}
      onClick={openTestimonialModal(index)}
    />,
    <DeleteIcon
      className={classes.deleteIcon}
      fontSize="small"
      onClick={openDeleteTestimonialModal(index)}
    />,
  ]);

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button color="black" round={true} onClick={handleAddTestimonial}>
          Create
        </Button>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Name", "Text", "", ""]}
                tableData={tableData}
                coloredColls={[3]}
                colorsColls={["primary"]}
                pagination
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <CustomModal open={showTestimonialModal} onClose={closeTestimonialModal}>
        <form onSubmit={handleSubmit(handleSaveTestimonial)}>
          <Controller
            render={(props) => (
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: handleChange("name"),
                  value: props.value,
                }}
                labelText="Name"
                id="name"
                error={!!errors.name?.message}
                helperText={errors.name?.message}
                // name="name"
              />
            )}
            name="name"
            control={control}
            rules={{ required: "Required" }}
          />
          <Controller
            render={(props) => (
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: handleChange("text"),
                  value: props.value,
                }}
                labelText="Text"
                id="text"
                error={!!errors.text?.message}
                helperText={errors.text?.message}
                // name="text"
              />
            )}
            name="text"
            control={control}
            rules={{ required: "Required" }}
          />
          <div className={classes.modalButtonContainer}>
            <Button
              color="danger"
              round={true}
              loadingColor="white"
              onClick={closeTestimonialModal}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="black"
              round={true}
              loading={isLoading && !pageLoading}
              loadingColor="white"
              className={classes.saveButton}
            >
              Save
            </Button>
          </div>
        </form>
      </CustomModal>

      <CustomModal
        open={showDeleteTestimonialModal}
        onClose={closeDeleteTestimonialModal}
      >
        <h5>
          Are you sure you want to delete this testimonial "
          {testimonials[currentTestimonial]?.name}"?
        </h5>
        <div className={classes.modalButtonContainer}>
          <Button
            color="black"
            round={true}
            loadingColor="white"
            onClick={closeDeleteTestimonialModal}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="danger"
            round={true}
            loading={isLoading && !pageLoading}
            loadingColor="white"
            onClick={handleDeleteTestimonial}
            className={classes.saveButton}
          >
            Delete
          </Button>
        </div>
      </CustomModal>
    </div>
  );
}
