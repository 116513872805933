// import logo from "./logo.svg";
// import "./App.css";

import React from "react";
import { createBrowserHistory } from "history";
import { Route, Router, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Login from "views/login/Login";
// import Home from "views/home/Home";
// import Admin from "views/admin/Admin";
// import Sidebar from "components/Sidebar/Sidebar";
import { AuthContext } from "shared/context/auth-context";
import { useAuth } from "shared/hooks/auth-hook";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import "./App.css";

var hist = createBrowserHistory();

const App = () => {
  const { userData, login, logout } = useAuth();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (userData !== false) {
      setIsLoading(false);
    }
  }, [userData]);

  let routes;

  if (!!userData) {
    routes = [
      <Route path="/admin" component={AdminLayout} key="admin-route" />,
      <Redirect from="/" to="/admin/home" key="redirect" />,
    ];
  } else {
    routes = [
      <Route path="/auth" component={AuthLayout} key="auth-route" />,
      <Redirect to="/auth" key="redirect" />,
    ];
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!userData,
        userData: userData,
        login: login,
        logout: logout,
      }}
    >
      <Router history={hist}>
        <main>
          {isLoading ? (
            <div className="loading-container">
              <CircularProgress className="circular-progress" />
            </div>
          ) : (
            <div className="wrapper">
              <Switch>{routes}</Switch>
            </div>
          )}
        </main>
      </Router>
    </AuthContext.Provider>
  );
};

// const App = () => (
//   <Router history={hist}>
//     <Switch>
//       <Route path="/rtl" component={RtlLayout} />
//       <Route path="/auth" component={AuthLayout} />
//       <Route path="/admin" component={AdminLayout} />
//       <Redirect from="/" to="/admin/dashboard" />
//     </Switch>
//   </Router>
// );

export default App;
