// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import RichTextInput from "components/CustomInput/RichTextInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./investmentProjectDetailsStyle";

const useStyles = makeStyles(styles);

export default function InvestmentProjectDetails(props) {
  React.useEffect(() => {
    if (props?.investmentProject) {
      Object.keys(props.investmentProject).forEach((key) =>
        setValue(key, props.investmentProject[key])
      );
      if (props.investmentProject?.content)
        setContent(props.investmentProject.content);
      if (props.investmentProject?.contentText)
        setContentText(props.investmentProject.contentText);
      setInvestmentProjectId(props.investmentProject.id);
      setPageLoading(false);
    }
  }, [props]);

  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: { title: "", subtitle: "" },
  });

  const [pageLoading, setPageLoading] = React.useState(true);

  const [investmentProjectId, setInvestmentProjectId] = React.useState();

  const [contentText, setContentText] = React.useState();

  const [content, setContent] = React.useState();

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const handleContentText = (editorState) => {
    setContentText(editorState.getCurrentContent().getPlainText());
  };

  const updateInvestmentProject = async () => {
    try {
      const response = await sendRequest(
        api.updateInvestmentProjectDetails({
          ...(investmentProjectId ? { id: investmentProjectId } : {}),
          title: getValues("title"),
          subtitle: getValues("subtitle"),
          content,
          contentText,
        })
      );

      if (!investmentProjectId) {
        setInvestmentProjectId(response.id);
        history.push({
          pathname: `/admin/investment-projects/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardBody>
            <form onSubmit={handleSubmit(updateInvestmentProject)}>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: handleChange("title"),
                      value: props.value,
                    }}
                    labelText="Title"
                    error={!!errors.title?.message}
                    helperText={errors.title?.message}
                  />
                )}
                name="title"
                control={control}
                rules={{ required: "Required" }}
              />
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: handleChange("subtitle"),
                      value: props.value,
                    }}
                    labelText="Subtitle"
                  />
                )}
                name="subtitle"
                control={control}
              />
              <div>
                <label className={classes.contentLabel}>Content</label>
                <RichTextInput
                  rootStyle={{ marginBottom: 50 }}
                  value={content}
                  setValue={(value) => setContent(value)}
                  onChange={handleContentText}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="black"
                  round={true}
                  type="submit"
                  loading={isLoading && !pageLoading}
                  loadingColor="white"
                  className={classes.button}
                >
                  {`${investmentProjectId ? "Update" : "Create"}`}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
