// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import RichTextInput from "components/CustomInput/RichTextInput";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(styles);

export default function PropertyManagement() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getPropertyManagementPage());
        setPropertyManagement(response);
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [sendRequest, isLoading] = useHttpClient();

  const [propertyManagement, setPropertyManagement] = React.useState();

  const handleChangePropertyManagement = (key) => (value) => {
    setPropertyManagement((prevPropertyManagement) => ({ ...prevPropertyManagement, [key]: value }));
  };

  const handleUpdatePropertyManagementPage = async () => {
    try {
      const response = await sendRequest(api.updatePropertyManagementPage(propertyManagement));
      setPropertyManagement(response);
    } catch (err) {}
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          color="black"
          round={true}
          loading={isLoading && !pageLoading}
          loadingColor="white"
          onClick={handleUpdatePropertyManagementPage}
        >
          Publish
        </Button>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                Section One
              </h3>
            </CardHeader>
            <CardBody>
              <RichTextInput
                rootStyle={{ marginBottom: 20 }}
                value={propertyManagement?.sectionOne}
                setValue={handleChangePropertyManagement("sectionOne")}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                Section Two
              </h3>
            </CardHeader>
            <CardBody>
              <RichTextInput
                rootStyle={{ marginBottom: 20 }}
                value={propertyManagement?.sectionTwo}
                setValue={handleChangePropertyManagement("sectionTwo")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
