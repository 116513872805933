// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import RichTextInput from "components/CustomInput/RichTextInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./blogAssignStyle";

const useStyles = makeStyles(styles);

export default function BlogAssign() {
  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: { title: "", date: "" },
  });

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();

      if (id !== "create") {
        try {
          const response = await sendRequest(api.getBlog(id));
          setValue("title", response.title);
          setValue("date", response.date);
          setContent(response.content);
          if (response.image) setImage(response.image);
          setBlogId(response.id);
          if (response?.contentText) setContentText(response?.contentText);
        } catch (err) {
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [blogId, setBlogId] = React.useState();
  const [image, setImage] = React.useState(null);

  const [content, setContent] = React.useState();

  const [contentText, setContentText] = React.useState();

  const [sendRequest, isLoading] = useHttpClient();

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const handleContentText = (editorState) => {
    setContentText(editorState.getCurrentContent().getPlainText());
  };

  const updateBlog = async () => {
    const file = image ? (typeof image === "string" ? 1 : image) : 0;

    try {
      const response = await sendRequest(
        api.updateBlog({
          ...(blogId ? { id: blogId } : {}),
          title: getValues("title"),
          date: getValues("date"),
          content,
          contentText,
          file,
        })
      );

      if (response.image) setImage(response.image);
      if (!blogId) {
        setBlogId(response.id);
        history.push({
          pathname: `/admin/blogs/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(updateBlog)}>
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: handleChange("title"),
                        value: props.value,
                      }}
                      labelText="Title"
                      error={!!errors.title?.message}
                      helperText={errors.title?.message}
                    />
                  )}
                  name="title"
                  control={control}
                  rules={{ required: "Required" }}
                />
                <Controller
                  render={(props) => (
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: handleChange("date"),
                        value: props.value,
                      }}
                      labelText="Date"
                    />
                  )}
                  name="date"
                  control={control}
                />

                <div>
                  <label className={classes.contentLabel}>Content</label>
                  <RichTextInput
                    rootStyle={{ marginBottom: 50 }}
                    value={content}
                    setValue={(value) => setContent(value)}
                    onChange={handleContentText}
                  />
                </div>

                <ImageUpload
                  file={image}
                  setFile={setImage}
                  addButtonProps={{
                    color: "black",
                    round: true,
                  }}
                  changeButtonProps={{
                    color: "black",
                    round: true,
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: true,
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="black"
                    round={true}
                    type="submit"
                    loading={isLoading && !pageLoading}
                    loadingColor="white"
                    className={classes.button}
                  >
                    {`${blogId ? "Update" : "Create"}`}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
