// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import UploadImagesIcon from "@material-ui/icons/Image";
import InfoIcon from "@material-ui/icons/Info";
import SortImagesIcon from "@material-ui/icons/Sort";
import CustomTabs from "components/CustomTabs/CustomTabs";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import InvestmentProjectDetails from "../../components/InvestmentProjectDetails/InvestmentProjectDetails";
import InvestmentProjectImagesSort from "../../components/InvestmentProjectImagesSort/InvestmentProjectImagesSort";
import InvestmentProjectImagesUpload from "../../components/InvestmentProjectImagesUpload/InvestmentProjectImagesUpload";
import styles from "./investmentProjectAssignStyle";

const useStyles = makeStyles(styles);

export default function InvestmentProjectAssign() {
  const classes = useStyles();
  const [id] = React.useState(window.location.pathname.split("/").pop().trim());

  React.useEffect(() => {
    (async () => {
      if (id !== "create") {
        try {
          const response = await sendRequest(api.getInvestmentProject(id));
          setInvestmentProject(response);
        } catch (err) {}
      }
    })();
  }, []);

  const [investmentProject, setInvestmentProject] = React.useState();

  const [sendRequest] = useHttpClient();

  const handleUpdateInvestmentProjectImages = (data) =>
    setInvestmentProject((prevInvestmentProject) => ({
      ...prevInvestmentProject,
      ...data,
    }));

  let tabs = [
    {
      tabName: "Details",
      tabIcon: InfoIcon,
      tabContent: (
        <InvestmentProjectDetails investmentProject={investmentProject} />
      ),
    },
  ];

  if (id !== "create") {
    tabs.push(
      {
        tabName: "Upload Images",
        tabIcon: UploadImagesIcon,
        tabContent: (
          <InvestmentProjectImagesUpload
            investmentProject={investmentProject}
            setInvestmentProjectImages={handleUpdateInvestmentProjectImages}
          />
        ),
      },
      {
        tabName: "Sort Images",
        tabIcon: SortImagesIcon,
        tabContent: (
          <InvestmentProjectImagesSort investmentProject={investmentProject} />
        ),
      }
    );
  }

  return (
    <div>
      <CustomTabs
        headerColor="primary"
        rootClasses={classes.customTabs}
        tabs={tabs}
      />
    </div>
  );
}
