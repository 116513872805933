export default {
  required: { required: "Password is required." },
  username: {
    required: "Username is required.",
    maxLength: {
      value: 50,
      message: "Username cannot exceed 60 characters.",
    },
  },
  password: {
    minLength: {
      value: 8,
      message: "Password should be at least 8 characters.",
    },
    maxLength: {
      value: 60,
      message: "Password cannot exceed 60 characters.",
    },
  },
};
