import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Pagination from "components/Pagination/Pagination.js";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

export default function CustomGridList(props) {
  const {
    pagination,
    backendPagination,
    itemsPerPage,
    currentPage,
    noDataMessage,
    totalPosts,
    CustomGridItem,
    data,
    loadingData,
  } = props;
  let { paginate } = props;

  const [values, setValues] = React.useState({
    itemsPerPage,
    currentPage,
    customPagination: paginate !== undefined,
    backendData: data,
  });

  React.useEffect(() => {
    // window.scrollTo(0, 0);
    const mainPanel = document.querySelector("#main-panel");
    mainPanel.scrollTop = 0;
  }, [values.currentPage, currentPage]);

  const [, forceRerender] = React.useState(0);

  // force rerender on resizing the window
  useEffect(() => {
    window.addEventListener("resize", () => forceRerender((n) => !n));
    return () => {
      window.removeEventListener("resize", () => forceRerender((n) => !n));
    };
  }, []);

  useEffect(() => {
    setValues({ ...values, backendData: data });
  }, [data]);

  const classes = useStyles();

  if (!values.customPagination) {
    paginate = (pageNumber) => {
      setValues({ ...values, currentPage: pageNumber });
    };
  }

  let matchingRows = data;
  let currentRows = data;

  if (pagination) {
    const indexOfLastRow =
      (values.customPagination ? currentPage : values.currentPage) *
      values.itemsPerPage;
    const indexOfFirstRow = indexOfLastRow - values.itemsPerPage;
    currentRows = matchingRows.slice(indexOfFirstRow, indexOfLastRow);
  }

  return loadingData ? (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "40vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        style={{ color: "black" }}
        className="circular-progress"
      />
    </div>
  ) : (
    <div>
      <GridContainer>
        {currentRows.map((el, index) => (
          <GridItem xs={12} sm={12} md={4} key={`grid-item-${index}`}>
            <CustomGridItem data={el} />
          </GridItem>
        ))}
      </GridContainer>
      {(pagination || backendPagination) && data.length !== 0 ? (
        <div className={classes.paginationContainer}>
          <Pagination
            color="primary"
            postsPerPage={values.itemsPerPage}
            totalPosts={totalPosts ? totalPosts : matchingRows.length}
            paginate={paginate}
            currentPage={
              values.customPagination ? currentPage : values.currentPage
            }
          />
        </div>
      ) : null}
      {data.length === 0 ? (
        <div className={classes.noData}>
          {noDataMessage === "default" ? "No available data" : noDataMessage}
        </div>
      ) : null}
    </div>
  );
}

CustomGridList.defaultProps = {
  pagination: false,
  backendPagination: false,
  itemsPerPage: 9,
  currentPage: 1,
  noDataMessage: "default",
  loadingData: false,
};

CustomGridList.propTypes = {
  pagination: PropTypes.bool,
  backendPagination: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  paginate: PropTypes.func,
  noDataMessage: PropTypes.string,
  totalPosts: PropTypes.number,
  data: PropTypes.array,
  loadingData: PropTypes.bool,
  CustomGridItem: PropTypes.elementType,
};
