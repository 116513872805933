import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  withCredentials: true,
});

const login = (authData) => axiosInstance.post("auth/admin/login", authData);

const getHomePage = () => axiosInstance.get("home");

const updateHomePage = async (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return await axiosInstance.put(`home`, formData, config);
};

const getAboutUsPage = () => axiosInstance.get("about");

const updateAboutUsPage = (data) => axiosInstance.put("about", data);

const getLocations = () => axiosInstance.get("locations");

const updateLocation = (data) => axiosInstance.put("locations", data);

const deleteLocation = (id) => axiosInstance.delete(`locations/${id}`);

const getNewsletter = () => axiosInstance.get("newsletter");

const getTeam = (page, limit, search) =>
  axiosInstance.get("team-members", {
    params: { page, limit, search },
  });

const updateTeamMember = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axiosInstance.put(`team-members`, formData, config);
};

const getTeamMember = (id) => axiosInstance.get(`team-members/${id}`);

const deleteTeamMember = (id) => axiosInstance.delete(`team-members/${id}`);

const getPartners = (page, limit, search) =>
  axiosInstance.get("partners", {
    params: { page, limit, search },
  });

const updatePartner = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axiosInstance.put(`partners`, formData, config);
};

const getPartner = (id) => axiosInstance.get(`partners/${id}`);

const deletePartner = (id) => axiosInstance.delete(`partners/${id}`);

const getBlogs = (page, limit, search) =>
  axiosInstance.get("blogs", {
    params: { page, limit, search },
  });

const updateBlog = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axiosInstance.put(`blogs`, formData, config);
};

const getBlog = (id) => axiosInstance.get(`blogs/${id}`);

const deleteBlog = (id) => axiosInstance.delete(`blogs/${id}`);

const getInvestmentProjects = (page, limit, search) =>
  axiosInstance.get("investment-projects", {
    params: { page, limit, search },
  });

const updateInvestmentProjectDetails = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axiosInstance.put(`investment-projects`, formData, config);
};

const getInvestmentProject = (id) =>
  axiosInstance.get(`investment-projects/${id}`);

const deleteInvestmentProject = (id) =>
  axiosInstance.delete(`investment-projects/${id}`);

const uploadInvestmentProjectImages = (data, investmentProjectId) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === "files" && data[key].length > 0)
      data[key].forEach((file) => formData.append(`files`, file));
    else formData.append(key, data[key]);
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axiosInstance.post(
    `investment-projects/${investmentProjectId}/images`,
    formData,
    config
  );
};

const deleteInvestmentProjectImage = (investmentProjectId, image) =>
  axiosInstance.delete(
    `investment-projects/${investmentProjectId}/images/${image}`
  );

const updateInvestmentProjectImages = ({
  investmentProjectId,
  images,
  defaultImage,
}) =>
  axiosInstance.put(`investment-projects/${investmentProjectId}/images/sort`, {
    images,
    defaultImage,
  });

const getTestimonials = () => axiosInstance.get("testimonials");

const updateTestimonial = (data) => axiosInstance.put("testimonials", data);

const deleteTestimonial = (id) => axiosInstance.delete(`testimonials/${id}`);

const getPropertyManagementPage = () =>
  axiosInstance.get("property-management");

const updatePropertyManagementPage = (data) =>
  axiosInstance.put("property-management", data);

const getAdmins = () => axiosInstance.get("admins");

const updateAdmin = (data) => axiosInstance.put("admins", data);

const getAdmin = (id) => axiosInstance.get(`admins/${id}`);

const getProperties = (page, limit, search) =>
  axiosInstance.get("properties", {
    params: { page, limit, search },
  });

const updatePropertyDetails = (data) => axiosInstance.put("properties", data);

const getProperty = (id) => axiosInstance.get(`properties/${id}`);

const deleteProperty = (id) => axiosInstance.delete(`properties/${id}`);

const uploadPropertyImages = (data, propertyId) => {
  let formData = new FormData();
  // Object.keys(data).forEach((key) => formData.append(key, data[key]));
  Object.keys(data).forEach((key) => {
    if (key === "files" && data[key].length > 0)
      data[key].forEach((file) => formData.append(`files`, file));
    else formData.append(key, data[key]);
  });

  const config = {
    // onUploadProgress: function (progressEvent) {
    //   var percentCompleted = Math.round(
    //     (progressEvent.loaded * 100) / progressEvent.total
    //   );
    //   console.log("percentCompleted", percentCompleted);
    // },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axiosInstance.post(
    `properties/${propertyId}/images`,
    formData,
    config
  );
};

const deletePropertyImage = (propertyId, image) =>
  axiosInstance.delete(`properties/${propertyId}/images/${image}`);

const updatePropertyImages = ({ propertyId, images, defaultImage }) =>
  axiosInstance.put(`properties/${propertyId}/images/sort`, {
    images,
    defaultImage,
  });

export default {
  login,
  getHomePage,
  updateHomePage,
  getAboutUsPage,
  updateAboutUsPage,
  getLocations,
  updateLocation,
  deleteLocation,
  getNewsletter,
  getTeam,
  updateTeamMember,
  getTeamMember,
  getPartners,
  updatePartner,
  getPartner,
  getBlogs,
  updateBlog,
  getBlog,
  getInvestmentProjects,
  updateInvestmentProjectDetails,
  getInvestmentProject,
  uploadInvestmentProjectImages,
  deleteInvestmentProjectImage,
  updateInvestmentProjectImages,
  getTestimonials,
  updateTestimonial,
  deleteTestimonial,
  getPropertyManagementPage,
  updatePropertyManagementPage,
  getAdmins,
  updateAdmin,
  getAdmin,
  getProperties,
  updatePropertyDetails,
  getProperty,
  uploadPropertyImages,
  deletePropertyImage,
  updatePropertyImages,
  deleteBlog,
  deletePartner,
  deleteTeamMember,
  deleteProperty,
  deleteInvestmentProject,
};
