import {
  defaultFont,
  successColor,
} from "assets/jss/material-dashboard-pro-react.js";
import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";

const adminListStyle = (theme) => ({
  ...customDropdownStyle(theme),
  search: {
    margin: "0",
    paddingTop: "7px",
    paddingBottom: "7px",
    // [theme.breakpoints.down("sm")]: {
    //   margin: "10px 15px",
    //   float: "none !important",
    //   paddingTop: "1px",
    //   paddingBottom: "1px",
    //   padding: "10px 15px",
    //   width: "auto",
    // },
  },
  searchInput: {
    paddingTop: "2px",
  },
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0!important",
    textTransform: "none",
  },
  buttonLink: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "5px 15px 0",
      width: "auto",
      height: "auto",
      "& svg": {
        width: "30px",
        height: "24px",
        marginRight: "19px",
        marginLeft: "3px",
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        width: "30px",
        fontSize: "24px",
        lineHeight: "30px",
        marginRight: "19px",
        marginLeft: "3px",
      },
    },
  },
  searchButton: {
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      //   top: "-50px !important",
      marginRight: "38px",
      float: "right",
    },
  },
  top: {
    zIndex: "4",
  },
  searchIcon: {
    width: "17px",
    zIndex: "4",
  },
  links: {
    width: "20px",
    height: "20px",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "30px",
      height: "30px",
      color: "inherit",
      opacity: "0.8",
      marginRight: "16px",
      marginLeft: "-5px",
    },
  },
  managerClasses: {
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
    },
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important",
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  topContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  searchContainer: { display: "flex", alignItems: "center" },
  editIcon: { cursor: "pointer", color: "black" },
});

export default adminListStyle;
