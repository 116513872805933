// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import React from "react";
import { Link } from "react-router-dom";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./adminListStyle";

const useStyles = makeStyles(styles);

export default function AdminList() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const admins = await sendRequest(api.getAdmins());
        setAdmins(admins);
      } catch (err) {}
    })();
  }, []);

  const [admins, setAdmins] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();

  const tableData = admins.map((admin) => [
    admin.username,
    admin.name,
    <Link to={`/admin/admins/edit/${admin.id}`}>
      <EditIcon className={classes.editIcon} />
    </Link>,
  ]);

  return (
    <div>
      <div className={classes.topContainer}>
        <Link to="/admin/admins/create">
          <Button color="black" round={true}>
            Create
          </Button>
        </Link>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Username", "Name", ""]}
                tableData={tableData}
                pagination
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
