import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import "./SortableImages.css";
import styles from "./sortableImagesStyle";

const useStyles = makeStyles(styles);

const SortableImages = (props) => {
  const classes = useStyles();

  const SortableItem = SortableElement(({ item, idx }) => (
    <div
      className="item"
      style={{
        border: `5px solid ${
          props.defaultImage == idx ? "black" : "transparent"
        }`,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {props.defaultImage != idx ? (
          <Tooltip
            id="tooltip-top"
            title="Set as Default"
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <EditIcon
              className={classes.editIcon}
              onClick={props.onSetDefaultImage(idx)}
            />
          </Tooltip>
        ) : (
          <div />
        )}

        <Tooltip
          id="tooltip-top"
          title="Delete"
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <DeleteIcon
            className={classes.deleteIcon}
            onClick={props.onDeleteImage(idx)}
          />
        </Tooltip>
      </div>
      <div className="inner-item">
        <img
          style={{
            cursor: "pointer",
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
          className="d-block w-100"
          src={item}
        />
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div className="container">
      {items.map((item, index) => {
        return (
          <SortableItem
            key={`image-${index}`}
            index={index}
            idx={index}
            item={item}
          />
        );
      })}
    </div>
  ));

  return (
    <SortableList
      items={props.items}
      onSortEnd={props.onSortEnd}
      axis="xy"
      helperClass="SortableHelper"
      useWindowAsScrollContainer={true}
      distance={1}
    />
  );
};

export default withStyles(styles)(SortableImages);
