// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import DropFiles from "components/DropFiles/DropFiles";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./propertyImagesUploadStyle";

const useStyles = makeStyles(styles);

export default function PropertyImagesUpload(props) {
  React.useEffect(() => {
    if (props?.property) {
      setPropertyId(props.property.id);
      setPageLoading(false);
    }
  }, [props]);

  const classes = useStyles();

  const scrollToTop = () => {
    const mainPanel = document.querySelector("#main-panel");
    mainPanel.scrollTop = 0;
  };

  const [pageLoading, setPageLoading] = React.useState(true);

  const [propertyId, setPropertyId] = React.useState();

  const [sendRequest, isLoading] = useHttpClient();

  const [files, setFiles] = React.useState([]);

  const onDrop = (droppedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleRemoveFile = (index) => () => {
    let updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleUploadImages = async () => {
    try {
      const response = await sendRequest(
        api.uploadPropertyImages({ files }, propertyId)
      );
      resetState();
      props.setPropertyImages(response);
    } catch (err) {}
  };

  const resetState = () => {
    setFiles([]);
    scrollToTop();
  };

  return (
    <Card>
      <CardBody>
        <DropFiles
          onDrop={onDrop}
          files={files}
          onFileRemoved={handleRemoveFile}
        />
        <div className={classes.buttonContainer}>
          <Button
            color="black"
            round={true}
            loading={isLoading && !pageLoading}
            loadingColor="white"
            className={classes.button}
            onClick={handleUploadImages}
          >
            Upload
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
