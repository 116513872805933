// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckBoxDropdown from "components/CheckBoxDropdown/CheckBoxDropdown";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import RichTextInput from "components/CustomInput/RichTextInput";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SelectInput from "components/SelectInput/SelectInput";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./propertyDetailsStyle";
import strings from "constants/strings";

const useStyles = makeStyles(styles);

const fields = [
  "name",
  "refId",
  "area",
  "price",
  "numberOfBedrooms",
  "numberOfBathrooms",
  "rentalType",
  "rentOrBuy",
  "type",
  "location",
  "isFurnished",
  "isFeatured",
  "isLatest",
];

export default function PropertyDetails(props) {
  React.useEffect(() => {
    setLocations(props.locations);
    if (props?.property) {
      Object.keys(props.property).forEach((key) =>
        setValue(key, props.property[key])
      );
      if (props.property?.description)
        setDescription(props.property.description);
      if (props.property?.descriptionText)
        setDescriptionText(props.property.descriptionText);
      if (props.property?.amenities) setAmenities(props.property.amenities);
      setPropertyId(props.property.id);
      setPageLoading(false);
    }
  }, [props]);

  const classes = useStyles();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      refId: "",
      area: "",
      price: "",
      numberOfBedrooms: "",
      numberOfBathrooms: "",
      rentalType: -1,
      rentOrBuy: -1,
      type: -1,
      location: "",
      isFurnished: false,
      isFeatured: false,
      isLatest: false,
    },
    // shouldUnregister: false,
  });

  const [pageLoading, setPageLoading] = React.useState(true);

  const [propertyId, setPropertyId] = React.useState();
  const [locations, setLocations] = React.useState([]);
  const [image, setImage] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const [description, setDescription] = React.useState();
  const [descriptionText, setDescriptionText] = React.useState();

  const [amenities, setAmenities] = React.useState([]);

  const amenityCheckBoxHandler = (event) => setAmenities(event.target.value);

  const handleChange = (prop, inputType = "text") => (event) => {
    console.log(prop);
    console.log(event.target.value);

    const value =
      inputType === "boolean" ? event.target.checked : event.target.value;
    setValue(prop, value);
  };

  const handleDescriptionText = (editorState) => {
    setDescriptionText(editorState.getCurrentContent().getPlainText());
  };

  const updatePropertyDetails = async () => {
    let data = {};
    if (propertyId) data.id = propertyId;
    if (description) {
      data.description = description;
      data.descriptionText = descriptionText;
    }

    if (amenities.length > 0) data.amenities = amenities;

    // const rteContent = convertToRaw(event.getCurrentContent()))
    fields.forEach((field) => {
      if (getValues(field) !== "" && getValues(field) !== -1) {
        data[field] = getValues(field);
      }
    });
    try {
      const response = await sendRequest(api.updatePropertyDetails(data));

      if (!propertyId) {
        setPropertyId(response.id);
        history.push({
          pathname: `/admin/properties/edit/${response.id}`,
        });
      }
    } catch (err) {}
  };

  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit(updatePropertyDetails)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: handleChange("name"),
                      value: props.value,
                    }}
                    labelText="Name"
                    error={!!errors.name?.message}
                    helperText={errors.name?.message}
                  />
                )}
                name="name"
                control={control}
                // rules={{ required: "Required" }}
              />

              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: handleChange("area"),
                      value: props.value,
                    }}
                    labelText="Area (㎡)"
                    error={!!errors.area?.message}
                    helperText={errors.area?.message}
                  />
                )}
                name="area"
                control={control}
                // rules={{ required: "Required" }}
              />

              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: handleChange("numberOfBedrooms"),
                      value: props.value,
                    }}
                    labelText="Number of Bedrooms"
                    error={!!errors.numberOfBedrooms?.message}
                    helperText={errors.numberOfBedrooms?.message}
                  />
                )}
                name="numberOfBedrooms"
                control={control}
                // rules={{ required: "Required" }}
              />

              <Controller
                render={(props) => (
                  <SelectInput
                    id="rental-type"
                    labelText="Rental Type"
                    placeholder="Rental Type"
                    data={strings.rentalType.map((el, index) => ({
                      name: el,
                      value: index,
                    }))}
                    value={props.value}
                    // onSelect={(event) =>
                    //   setValue("rentalType", event.target.value)
                    // }
                    onSelect={handleChange("rentalType")}
                    handleChange
                    // errorMessage={
                    //   selectedLecture.error && selectedLecture.error[lang]
                    // }
                  />
                )}
                name="rentalType"
                control={control}
                // rules={{ required: "Required" }}
              />

              <Controller
                render={(props) => (
                  <SelectInput
                    id="rent-or-buy"
                    labelText="Rent or Buy"
                    placeholder="Rent or Buy"
                    data={strings.rentOrBuyArray.map((el, index) => ({
                      name: el,
                      value: index,
                    }))}
                    value={props.value}
                    // onSelect={(event) =>
                    //   setValue("rentOrBuy", event.target.value)
                    // }
                    onSelect={handleChange("rentOrBuy")}
                    // errorMessage={
                    //   selectedLecture.error && selectedLecture.error[lang]
                    // }
                  />
                )}
                name="rentOrBuy"
                control={control}
                // rules={{ required: "Required" }}
              />

              <CheckBoxDropdown
                // error={this.state.showRequiredMessage.features}
                helperText={"* Select amenities"}
                labelText="Select Amenities"
                value={"-"}
                onSelect={amenityCheckBoxHandler}
                values={amenities}
                //label="Select type"
                options={[
                  "Central AC",
                  "Kitchen Appliances",
                  "Private Pool",
                  "Private Garden",
                  "Built-in Wardrobes",
                  "Balcony",
                  "Maid Room",
                  "Driver Room",
                  "Pets Allowed",
                  "Sea/Lagoon View",
                  "Pool View",
                  "Lagoon Access",
                  "Jetty",
                  "Private Parking",
                  "Heated Pool",
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: handleChange("refId"),
                      value: props.value,
                    }}
                    labelText="Ref ID"
                    error={!!errors.refId?.message}
                    helperText={errors.refId?.message}
                  />
                )}
                name="refId"
                control={control}
                // rules={{ required: "Required" }}
              />
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: handleChange("price"),
                      value: props.value,
                    }}
                    labelText="Price"
                    error={!!errors.price?.message}
                    helperText={errors.price?.message}
                  />
                )}
                name="price"
                control={control}
                // rules={{ required: "Required" }}
              />
              <Controller
                render={(props) => (
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      onChange: handleChange("numberOfBathrooms"),
                      value: props.value,
                    }}
                    labelText="Number of Bathrooms"
                    error={!!errors.numberOfBathrooms?.message}
                    helperText={errors.numberOfBathrooms?.message}
                  />
                )}
                name="numberOfBathrooms"
                control={control}
                // rules={{ required: "Required" }}
              />

              <Controller
                render={(props) => (
                  <SelectInput
                    id="property-type"
                    labelText="Property Type"
                    placeholder="Property Type"
                    data={[
                      "Apartment",
                      "Duplex",
                      "Penthouse",
                      "Studio",
                      "Town House",
                      "Twin Villa",
                      "Villa",
                    ].map((el, index) => ({
                      name: el,
                      value: index,
                    }))}
                    value={props.value}
                    // onSelect={(event) =>
                    //   setValue("type", event.target.value)
                    // }
                    onSelect={handleChange("type")}
                    // errorMessage={
                    //   selectedLecture.error && selectedLecture.error[lang]
                    // }
                  />
                )}
                name="type"
                control={control}
                // rules={{ required: "Required" }}
              />

              <Controller
                render={(props) => (
                  <SelectInput
                    id="location"
                    labelText="Location"
                    placeholder="Location"
                    data={locations.map((location) => ({
                      name: location.name,
                      value: location.id,
                    }))}
                    value={props.value}
                    // onSelect={(event) =>
                    //   setValue("location", event.target.value)
                    // }
                    onSelect={handleChange("location")}
                    // errorMessage={
                    //   selectedLecture.error && selectedLecture.error[lang]
                    // }
                  />
                )}
                name="location"
                control={control}
                // rules={{ required: "Required" }}
              />

              <FormControlLabel
                control={
                  <Controller
                    render={(props) => (
                      <Switch
                        checked={props.value}
                        // onChange={(event) =>
                        //   setValue(
                        //     "isFurnished",
                        //     event.target.checked
                        //   )
                        // }
                        onChange={handleChange("isFurnished", "boolean")}
                        value="isFurnished"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                    )}
                    name="isFurnished"
                    control={control}
                  />
                  // <Switch
                  //   checked={isFurnished}
                  //   onChange={(event) =>
                  //     setIsFurnished(event.target.checked)
                  //   }
                  //   value="isFurnished"
                  //   classes={{
                  //     switchBase: classes.switchBase,
                  //     checked: classes.switchChecked,
                  //     thumb: classes.switchIcon,
                  //     track: classes.switchBar,
                  //   }}
                  // />
                }
                classes={{
                  label: classes.label,
                }}
                label="Furnished"
              />

              <FormControlLabel
                control={
                  <Controller
                    render={(props) => (
                      <Switch
                        checked={props.value}
                        // value={props.value}
                        // onChange={(event) =>
                        //   setValue("isFeatured", event.target.checked)
                        // }
                        onChange={handleChange("isFeatured", "boolean")}
                        value="isFeatured"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                    )}
                    name="isFeatured"
                    control={control}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Featured"
              />

              <FormControlLabel
                control={
                  <Controller
                    render={(props) => (
                      <Switch
                        checked={props.value}
                        value={props.value}
                        // onChange={(event) =>
                        //   setValue("isLatest", event.target.checked)
                        // }
                        onChange={handleChange("isLatest", "boolean")}
                        value="isLatest"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                    )}
                    name="isLatest"
                    control={control}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Latest"
              />
            </GridItem>
          </GridContainer>

          <label className={classes.contentLabel}>Description</label>
          <RichTextInput
            rootStyle={{ marginBottom: 20 }}
            value={description}
            setValue={setDescription}
            onChange={handleDescriptionText}
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="black"
              round={true}
              type="submit"
              loading={isLoading && !pageLoading}
              loadingColor="white"
              className={classes.button}
            >
              {`${propertyId ? "Update" : "Create"}`}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}
