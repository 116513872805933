// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import CustomModal from "components/CustomModal/CustomModal";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(styles);

export default function Locations() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getLocations());
        setLocations(response);
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
    clearErrors,
  } = useForm({
    defaultValues: { location: "" },
  });

  const [locations, setLocations] = React.useState([]);

  const [currentLocation, setCurrentLocation] = React.useState();

  const [pageLoading, setPageLoading] = React.useState(true);

  const [showLocationModal, setShowLocationModal] = React.useState(false);

  const [showDeleteLocationModal, setShowDeleteLocationModal] = React.useState(
    false
  );

  const [sendRequest, isLoading] = useHttpClient();

  const handleChangeLocation = (event) => {
    setValue("location", event.target.value);
  };

  const handleAddLocation = () => {
    const newIndex = locations.length;
    openLocationModal(newIndex)();
  };

  const openLocationModal = (index) => () => {
    setShowLocationModal(true);
    setCurrentLocation(index);
    setValue("location", locations[index]?.name || "");
  };

  const closeLocationModal = () => {
    setShowLocationModal(false);
    setValue("location", "");
    setCurrentLocation(undefined);
    clearErrors("location");
  };

  const openDeleteLocationModal = (index) => () => {
    setShowDeleteLocationModal(true);
    setCurrentLocation(index);
  };

  const closeDeleteLocationModal = () => {
    setShowDeleteLocationModal(false);
    setCurrentLocation(undefined);
  };

  const handleSaveLocation = async () => {
    try {
      const response = await sendRequest(
        api.updateLocation({
          ...locations[currentLocation],
          name: getValues("location"),
        })
      );

      setLocations((prevLocations) => {
        let newLocations = [...prevLocations];
        newLocations[currentLocation] = response;
        return newLocations;
      });
    } catch (err) {
    } finally {
      closeLocationModal();
    }
  };

  const handleDeleteLocation = async () => {
    try {
      await sendRequest(api.deleteLocation(locations[currentLocation].id));

      setLocations((prevLocations) => {
        let newLocations = [...prevLocations];
        newLocations.splice(currentLocation, 1);
        return newLocations;
      });
    } catch (err) {
    } finally {
      closeDeleteLocationModal();
    }
  };

  return (
    <div>
      <GridContainer>
        {locations.map((el, index) => (
          <GridItem key={`location-${index}`}>
            <Card style={{ marginTop: 10, marginBottom: 5 }}>
              <CardHeader style={{ display: "flex" }}>
                <p
                  className={classes.cardCategory}
                  style={{ padding: 0, marginRight: 5, cursor: "pointer" }}
                  onClick={openLocationModal(index)}
                >
                  {el?.name}
                </p>
                <DeleteIcon
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                  onClick={openDeleteLocationModal(index)}
                />
              </CardHeader>
            </Card>
          </GridItem>
        ))}
        <GridItem>
          <Card
            style={{
              marginTop: 10,
              marginBottom: 5,
              cursor: "pointer",
            }}
            onClick={handleAddLocation}
          >
            <CardHeader
              style={{
                display: "flex",
                alignItems: "center",
                height: 44.8,
              }}
            >
              <AddCircleOutlineIcon style={{ padding: 0 }} />
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>

      <CustomModal open={showLocationModal} onClose={closeLocationModal}>
        <form onSubmit={handleSubmit(handleSaveLocation)}>
          <Controller
            render={(props) => (
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: handleChangeLocation,
                  value: props.value,
                }}
                labelText="Location"
                id="location"
                error={!!errors.location?.message}
                helperText={errors.location?.message}
                name="location"
              />
            )}
            name="location"
            control={control}
            rules={{ required: "Required" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="danger"
              round={true}
              loadingColor="white"
              onClick={closeLocationModal}
              style={{ marginRight: 5, width: 105 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="black"
              round={true}
              loading={isLoading && !pageLoading}
              loadingColor="white"
              // onClick={handleSaveLocation}
              style={{ width: 105 }}
            >
              Save
            </Button>
          </div>
        </form>
      </CustomModal>

      <CustomModal
        open={showDeleteLocationModal}
        onClose={closeDeleteLocationModal}
      >
        <h5>
          Are you sure you want to delete this location "
          {locations[currentLocation]?.name}"?
        </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="black"
            round={true}
            loadingColor="white"
            onClick={closeDeleteLocationModal}
            style={{ marginRight: 5, width: 105 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="danger"
            round={true}
            loading={isLoading && !pageLoading}
            loadingColor="white"
            onClick={handleDeleteLocation}
            style={{ width: 105 }}
          >
            Delete
          </Button>
        </div>
      </CustomModal>
    </div>
  );
}
