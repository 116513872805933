// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
import RichTextInput from "components/CustomInput/RichTextInput";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(styles);

export default function Home() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getHomePage());
        setAboutCEO(response.aboutCEO);
        setHomeText(response.homeText);
        setHomePlainText(response.homePlainText);
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [sendRequest, isLoading] = useHttpClient();

  const [aboutCEO, setAboutCEO] = React.useState();
  const [homeText, setHomeText] = React.useState();
  const [homePlainText, setHomePlainText] = React.useState();

  const handleChangeAboutCEO = (key) => (value) => {
    setAboutCEO((prevAboutCEO) => ({ ...prevAboutCEO, [key]: value }));
  };

  const handleHomePlainText = (editorState) => {
    setHomePlainText(editorState.getCurrentContent().getPlainText());
  };

  const handleHomeText = (value) => {
    setHomeText(value);
  };

  const handleUpdateHomePage = async () => {
    // const file = !!image ? (typeof image === "string" ? 1 : image) : 0;
    const imageAboutCEO = !!aboutCEO?.image
      ? typeof aboutCEO.image === "string"
        ? 1
        : aboutCEO.image
      : 0;
    try {
      const response = await sendRequest(
        api.updateHomePage({
          homeText,
          homePlainText,
          aboutCEO: JSON.stringify({
            title: aboutCEO.title,
            content: aboutCEO.content,
          }),
          file: imageAboutCEO,
        })
      );

      setAboutCEO(response.aboutCEO);
    } catch (err) {}
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          color="black"
          round={true}
          loading={isLoading && !pageLoading}
          loadingColor="white"
          onClick={handleUpdateHomePage}
        >
          Publish
        </Button>
      </div>
      <Card product className={classes.cardHover}>
        <CardBody>
          <h3 className={classes.heading}>Home Text</h3>
          <RichTextInput
            rootStyle={{ marginBottom: 80 }}
            value={homeText}
            setValue={handleHomeText}
            onChange={handleHomePlainText}
          />
          <h3 className={classes.heading}>About CEO</h3>
          <RichTextInput
            rootStyle={{ marginBottom: 20 }}
            value={aboutCEO?.title}
            setValue={handleChangeAboutCEO("title")}
          />
          <RichTextInput
            rootStyle={{ marginBottom: 50 }}
            value={aboutCEO?.content}
            setValue={handleChangeAboutCEO("content")}
          />

          <ImageUpload
            file={aboutCEO?.image}
            setFile={handleChangeAboutCEO("image")}
            addButtonProps={{
              color: "black",
              round: true,
            }}
            changeButtonProps={{
              color: "black",
              round: true,
            }}
            removeButtonProps={{
              color: "danger",
              round: true,
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
}
