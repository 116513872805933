// @material-ui/icons
import PropertiesIcon from "@material-ui/icons/ApartmentOutlined";
import InvestmentProjectsIcon from "@material-ui/icons/AttachMoneyOutlined";
import PropertyManagementIcon from "@material-ui/icons/Build";
import TestimonialsIcon from "@material-ui/icons/Chat";
import NewsletterIcon from "@material-ui/icons/Email";
import TeamIcon from "@material-ui/icons/Group";
import HomeIcon from "@material-ui/icons/Home";
import AboutUsIcon from "@material-ui/icons/Info";
import LocationsIcon from "@material-ui/icons/LocationOn";
import AdminsIcon from "@material-ui/icons/Lock";
import BlogIcon from "@material-ui/icons/ReceiptOutlined";
import PartnersIcon from "@material-ui/icons/WorkOutlined";
import AboutUs from "views/AboutUs/AboutUs";
import AdminAssign from "views/Admins/pages/AdminAssign/AdminAssign";
import AdminList from "views/Admins/pages/AdminList/AdminList";
import BlogAssign from "views/Blog/pages/BlogAssign/BlogAssign";
import BlogList from "views/Blog/pages/BlogList/BlogList";
import Home from "views/Home/Home";
import InvestmentProjectAssign from "views/InvestmentProjects/pages/InvestmentProjectAssign/InvestmentProjectAssign";
import InvestmentProjectList from "views/InvestmentProjects/pages/InvestmentProjectList/InvestmentProjectList";
import Locations from "views/Locations/Locations";
import Newsletter from "views/Newsletter/Newsletter";
import LoginPage from "views/Pages/LoginPage.js";
import PartnerAssign from "views/Partners/pages/PartnerAssign/PartnerAssign";
import PartnerList from "views/Partners/pages/PartnerList/PartnerList";
import PropertyAssign from "views/Properties/pages/PropertyAssign/PropertyAssign";
import PropertyList from "views/Properties/pages/PropertyList/PropertyList";
import PropertyManagement from "views/PropertyManagement/PropertyManagement";
import TeamAssign from "views/Team/pages/TeamAssign/TeamAssign";
import TeamList from "views/Team/pages/TeamList/TeamList";
import Testimonials from "views/Testimonials/Testimonials";

var dashRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: HomeIcon,
    component: Home,
    layout: "/admin",
  },
  {
    path: "/aboutus",
    name: "About Us",
    icon: AboutUsIcon,
    component: AboutUs,
    layout: "/admin",
  },
  {
    path: "/properties",
    name: "Properties",
    icon: PropertiesIcon,
    component: PropertyList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/properties/create",
        name: "Create Property",
        component: PropertyAssign,
        layout: "/admin",
      },
      {
        path: "/properties/edit/:id",
        name: "Edit Property",
        component: PropertyAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/admins",
    name: "Admins",
    icon: AdminsIcon,
    component: AdminList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/admins/create",
        name: "Create Admin",
        component: AdminAssign,
        layout: "/admin",
      },
      {
        path: "/admins/edit/:id",
        name: "Edit Admin",
        component: AdminAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/property-management",
    name: "Property Management",
    icon: PropertyManagementIcon,
    component: PropertyManagement,
    layout: "/admin",
  },
  {
    path: "/locations",
    name: "Locations",
    icon: LocationsIcon,
    component: Locations,
    layout: "/admin",
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    icon: NewsletterIcon,
    component: Newsletter,
    layout: "/admin",
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    icon: TestimonialsIcon,
    component: Testimonials,
    layout: "/admin",
  },
  {
    path: "/team",
    name: "Team",
    icon: TeamIcon,
    component: TeamList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/team/create",
        name: "Create Team Member",
        component: TeamAssign,
        layout: "/admin",
      },
      {
        path: "/team/edit/:id",
        name: "Edit Team Member",
        component: TeamAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/blogs",
    name: "Blogs",
    icon: BlogIcon,
    component: BlogList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/blogs/create",
        name: "Create Blog",
        component: BlogAssign,
        layout: "/admin",
      },
      {
        path: "/blogs/edit/:id",
        name: "Edit Blog",
        component: BlogAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/partners",
    name: "Partners",
    icon: PartnersIcon,
    component: PartnerList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/partners/create",
        name: "Create Partner",
        component: PartnerAssign,
        layout: "/admin",
      },
      {
        path: "/partners/edit/:id",
        name: "Edit Partner",
        component: PartnerAssign,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/investment-projects",
    name: "Investment Projects",
    icon: InvestmentProjectsIcon,
    component: InvestmentProjectList,
    layout: "/admin",
    children: true,
    views: [
      {
        path: "/investment-projects/create",
        name: "Create Investment Project",
        component: InvestmentProjectAssign,
        layout: "/admin",
      },
      {
        path: "/investment-projects/edit/:id",
        name: "Edit Investment Project",
        component: InvestmentProjectAssign,
        layout: "/admin",
      },
    ],
  },
  {
    hidden: true,
    path: "/login",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
];
export default dashRoutes;
