// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import defaultAvatar from "assets/img/default-image.png";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./investmentProjectListItemStyle";
import { useHttpClient } from "shared/hooks/http-hook";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import api from "services/api";
import Delete from "@material-ui/icons/Delete";

const useStyles = makeStyles(styles);

export default (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [sendRequest, loading] = useHttpClient();

  const deleteItem = async () => {
    try {
      await sendRequest(api.deleteInvestmentProject(props.data.id));
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  const classes = useStyles();

  return (
    <Card product className={classes.cardHover}>
      <ConfirmModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setTimeout(() => {
            setActiveScreen("question");
          }, 300);
        }}
        headerMessage="Delete Investment Project"
        confirmMessage={
          "Are you sure you want to delete " + props.data.title + "?"
        }
        errorMessage="Something went wrong."
        successMessage="Investment Project deleted successfully."
        confirmButtonText="Delete"
        confirmButtonColor="danger"
        onConfirm={deleteItem}
        loading={loading}
        activeScreen={activeScreen}
        refreshOnSuccess
      />
      <CardHeader image className={classes.cardHeaderHover}>
        {/* <Card product>
      <CardHeader image> */}
        <Link to={`/admin/investment-projects/edit/${props.data.id}`}>
          <img
            className={classes.cardImage}
            src={props.data.images[props.data.defaultImage] || defaultAvatar}
            alt="..."
          />
        </Link>
      </CardHeader>
      <CardBody>
        <div className={classes.cardHoverUnder}>
          <Tooltip
            id="tooltip-top"
            title="Edit"
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <Link to={`/admin/investment-projects/edit/${props.data.id}`}>
              <Button color="yellow" simple justIcon>
                <Edit className={classes.underChartIcons} />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip
            id="tooltip-delete"
            title="Delete"
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              onClick={() => setModalOpen(true)}
              color="danger"
              simple
              justIcon
            >
              <Delete className={classes.underChartIcons} />
            </Button>
          </Tooltip>
        </div>
        <h4 className={classes.cardProductTitle}>{props.data.title}</h4>
        <p className={classes.cardProductDesciprion}>{props.data.subtitle}</p>
      </CardBody>
    </Card>
  );
};
