// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Table from "components/Table/Table.js";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(styles);

export default function Locations() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getNewsletter());
        const emails = response.map((el) => el.email);
        setEmails(emails);
      } catch (err) {}
    })();
  }, []);

  const [emails, setEmails] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();

  const tableData = emails.map((email) => [email]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <a href={`mailto:${emails.join(";")}`}>
          <Button color="black" round={true}>
            Send Email
          </Button>
        </a>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Card>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Email"]}
                tableData={tableData}
                coloredColls={[3]}
                colorsColls={["primary"]}
                pagination
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
