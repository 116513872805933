// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import Refresh from "@material-ui/icons/Refresh";
import priceImage3 from "assets/img/card-1.jpeg";
import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import RichTextInput from "components/CustomInput/RichTextInput";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(styles);

export default function AboutUs() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getAboutUsPage());
        setAboutUs(response);
      } catch (err) {
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [sendRequest, isLoading] = useHttpClient();

  const [aboutUs, setAboutUs] = React.useState();

  const handleChangeAboutUs = (key) => (value) => {
    setAboutUs((prevAboutUs) => ({ ...prevAboutUs, [key]: value }));
  };

  const handleUpdateAboutUsPage = async () => {
    try {
      const response = await sendRequest(api.updateAboutUsPage(aboutUs));
      setAboutUs(response);
    } catch (err) {}
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          color="black"
          round={true}
          loading={isLoading && !pageLoading}
          loadingColor="white"
          onClick={handleUpdateAboutUsPage}
        >
          Publish
        </Button>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                Who We Are
              </h3>
            </CardHeader>
            <CardBody>
              <RichTextInput
                rootStyle={{ textAlign: "center", marginBottom: 20 }}
                value={aboutUs?.whoWeAre}
                setValue={handleChangeAboutUs("whoWeAre")}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                What We Do
              </h3>
            </CardHeader>
            <CardBody>
              <RichTextInput
                rootStyle={{ textAlign: "center", marginBottom: 20 }}
                value={aboutUs?.whatWeDo}
                setValue={handleChangeAboutUs("whatWeDo")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
